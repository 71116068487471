import { useEffect, useState } from 'react';

import { useAuth } from '@opendoor/auth-fe';
import Cookies from 'js-cookie';

import * as api from '../components/api';

const fetchSellerInputUUID = async (customerUUID?: string | null) => {
  let sellerInputUUID;
  let hasMultipleProperties = false;
  if (customerUUID) {
    // user is logged in
    // fetch addresses associated to customer
    const addressesResponse = await api.fetchAdressesByCustomerUUID(customerUUID);
    if (!addressesResponse.errors && addressesResponse.data.sellDirect.getAddressesByCustomerUUID) {
      const addresses = addressesResponse.data.sellDirect.getAddressesByCustomerUUID.addressToken;
      if (addresses.length > 0) {
        // fetch seller input uuid associated to customer and address
        const lead_response = await api.getLatestLeadQuery(customerUUID, addresses[0]);

        if (!lead_response.errors && lead_response.data.seller.getLatestLead) {
          sellerInputUUID = lead_response.data.seller.getLatestLead.lead.source.id;

          if (addresses.length > 1) {
            hasMultipleProperties = true;
          }
        }
      }
    }
  } else {
    sellerInputUUID = Cookies.get('lead_token');
  }

  return { sellerInputUUID, hasMultipleProperties };
};

const fetchSellerReturnExperience = async (customerUUID?: string | null) => {
  const { sellerInputUUID, hasMultipleProperties } = await fetchSellerInputUUID(customerUUID);
  if (!sellerInputUUID) {
    return;
  }
  const response = await api.fetchSellerReturnExperience(sellerInputUUID);
  if (!response || !response.data) {
    return;
  }
  // check for errors after parsing response
  // fetchSellerReturnExperience queries reception, web, and seller
  // any one of those calls could fail depending on where the user is in the flow

  // e.g. no leads are generated if user is in the middle of onboarding
  // so for incomplete_flow, the seller part of gql response will error, as expected

  // therefore we parse the response in getReturnExperienceData() and return early
  // if there is no eligible experience
  const returnExperienceData = getReturnExperienceData(
    response.data,
    sellerInputUUID,
    hasMultipleProperties,
  );
  if (returnExperienceData.experienceType === null) {
    return;
  }

  return {
    returnExperienceData,
  };
};

export enum ExperienceType {
  offer_expired,
  incomplete_flow,
  offer_expiring,
  offer_decreased,
  offer_increased,
  active_offer,
  multiple_properties,
}

export interface ReturnExperienceData {
  address?: string;
  addressToken?: string;
  customerUUID?: string;
  firstName?: string;
  offerRequestSource?: string;
  currentOfferHeadlinePriceCents?: number;
  previousOfferHeadlinePriceCents?: number;
  offerExpiresInDays?: number;
  sellerInputUUID?: string;
  experienceType: ExperienceType | null;
}

const OFFER_EXPIRY_DAYS_CUTOFF = 4;

export const getReturnExperienceData = (
  data: any,
  sellerInputUUID: string,
  hasMultipleProperties: boolean,
): ReturnExperienceData => {
  if (!data.sellDirect && !data.reception) {
    return {
      experienceType: null,
    };
  }

  // If the lead is denied for sell direct then there is no return experience for them.
  const productOfferings: Array<any> | undefined =
    data.reception.getSellerInput?.sellerInput.productOfferings;
  const sellDirectProductOffering = productOfferings?.find(
    (po) => po.product === 'OPENDOOR_DIRECT',
  );
  const sellDirectDenied = sellDirectProductOffering?.deniedAt;
  if (sellDirectDenied) {
    return {
      experienceType: null,
    };
  }

  // Without an email the lead could not have qualified and thus we want to show the incomplete experience at this stage
  if (!data.reception.getSellerInput?.sellerInput.answers.email) {
    return {
      sellerInputUUID: sellerInputUUID,
      address: data.reception.getSellerInput?.sellerInput.address.street,
      experienceType: ExperienceType.incomplete_flow,
    };
  }

  const lead = data.sellDirect.getLead?.lead;
  // At this point we have an email which means the lead went through qualification process in web.
  // The endpoint we are using is the offer endpoint in web (for speed purposes). If there is no offer it returns no lead even if a lead exists
  // Thus no lead means there is no offer and hence the lead was denied. In this case we have no return experience to show
  if (!lead) {
    return {
      experienceType: null,
    };
  }

  const address = lead.address?.street;
  const addressToken = lead.address?.uuid;
  const customerUUID = lead.customerV2?.uuid;
  const firstName = lead.customerV2?.firstName;
  const offerRequestSource = lead.latestOffer?.offerRequestSource;
  const currentOfferHeadlinePriceCents = lead.latestOffer?.headlinePriceCents;
  const previousOfferHeadlinePriceCents = lead.previousOffer?.headlinePriceCents;
  const offerState = lead.latestOffer?.state;
  const offerExpiresAt = lead.latestOffer?.preliminaryOfferExpirationDate;
  const isQualifiedLead = lead.isQualified;

  if (hasMultipleProperties) {
    return {
      customerUUID,
      firstName,
      address,
      experienceType: ExperienceType.multiple_properties,
    };
  }

  let offerExpiresInDays = undefined;
  if (offerExpiresAt) {
    const currentTime = new Date().getTime();
    const expirationTime = Date.parse(offerExpiresAt);
    // convert the milliseconds time difference into days and round up to nearest number of days
    offerExpiresInDays = Math.ceil((expirationTime - currentTime) / (1000 * 3600 * 24));
  }

  let experienceType = null;

  if (offerState == 'EXPIRED' && isQualifiedLead) {
    // https://github.com/opendoor-labs/code/blob/f6fb69d0fb84941597fb8084c470f9eac8aed67d/js/packages/cosmos/src/components/shared/ReturnExperienceBanner.tsx#L306 -> we show the refresh offer button if the experience type is "offer_expired"
    // let's only set the experience type to be "offer_expired" when we know that this lead is eligible for offer refresh (qualified for opendoor direct)
    experienceType = ExperienceType.offer_expired;
  } else if (
    offerState == 'SENT' &&
    offerExpiresInDays &&
    offerExpiresInDays > 0 &&
    offerExpiresInDays <= OFFER_EXPIRY_DAYS_CUTOFF
  ) {
    experienceType = ExperienceType.offer_expiring;
  } else if (
    offerState == 'SENT' &&
    currentOfferHeadlinePriceCents &&
    previousOfferHeadlinePriceCents &&
    currentOfferHeadlinePriceCents - previousOfferHeadlinePriceCents >= 1000
  ) {
    experienceType = ExperienceType.offer_increased;
  } else if (
    offerState == 'SENT' &&
    currentOfferHeadlinePriceCents &&
    previousOfferHeadlinePriceCents &&
    currentOfferHeadlinePriceCents < previousOfferHeadlinePriceCents
  ) {
    experienceType = ExperienceType.offer_decreased;
  } else if (offerState == 'SENT') {
    experienceType = ExperienceType.active_offer;
  }

  return {
    sellerInputUUID,
    address,
    addressToken,
    customerUUID,
    firstName,
    offerRequestSource,
    currentOfferHeadlinePriceCents,
    previousOfferHeadlinePriceCents,
    offerExpiresInDays,
    experienceType,
  };
};

// Custom hook to handle fetching return experience logic
export const useReturnExperience = (customerUuid?: string | null) => {
  const { user, authentication } = useAuth();

  const [returnExperienceData, setReturnExperienceData] = useState<ReturnExperienceData>({
    experienceType: null,
  });
  const returnExperienceBannerShown = returnExperienceData.experienceType !== null;
  const customerUUID = customerUuid || user?.customerUuid;
  useEffect(() => {
    // wait for auth to finish loading before getting return experience
    if (
      !(authentication.state === 'authenticated' || authentication.state === 'not_authenticated')
    ) {
      return;
    }

    async function start() {
      const result = await fetchSellerReturnExperience(customerUUID);
      if (!result) {
        return;
      }
      setReturnExperienceData(result.returnExperienceData);
    }

    start();
  }, [authentication.state, customerUUID]);

  return {
    returnExperienceData,
    returnExperienceBannerShown,
  };
};
